import React, { ReactNode } from 'react'
import { createRoot } from 'react-dom/client'
import { RootCss, shadowOuterStyle } from '../components/root-css'
import { ResetCss } from '../components/reset-css'
import { styled } from 'styled-components'
import shadowRoot from 'react-shadow/styled-components'

const Outer = styled(shadowRoot.div!)`
  ${shadowOuterStyle}
`

export const mount = (rootElementSelector: string, children: ReactNode) => {
  const mount = () => {
    const rootElement = document.querySelector(rootElementSelector)
    if(rootElement){
      const root = createRoot(rootElement)
      root.render(
        <Outer>
          <ResetCss />
          <RootCss />
          {children}
        </Outer>
      )
    }
  }
  if(document.readyState !== 'loading'){
    mount()
  } else {
    document.addEventListener('DOMContentLoaded', mount)
  }
  
}